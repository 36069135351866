var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"headline font-weight-bold mb-7",domProps:{"innerHTML":_vm._s(
			_vm.$t('app.student_meeting_summary', {
				user_name: _vm.fullName(_vm.current_student.user),
			})
		)}}),(_vm.list && _vm.list.length)?_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"wr_grey_1 subtitle-1",attrs:{"headers":_vm.headers,"calculate-widths":true,"items":_vm.items,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":"","hide-default-footer":"","hide-default-header":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
		var props = ref.props;
return _vm._l((props.headers),function(head,index){return _c('th',{key:index,staticClass:"font-weight-bold px-4 py-2",attrs:{"align":"start"}},[_vm._v(" "+_vm._s(_vm.$t(head.text))+" ")])})}},{key:"expanded-item",fn:function(ref){
		var headers = ref.headers;
		var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('MeetingSummary',{attrs:{"prop_meeting":item,"can_modify":false}})],1)]}}],null,false,696781503)})],1):_c('NoData',{attrs:{"heading":"app.no_meeting_summaries_yet","show_action":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }