<template>
	<StudentMeetingSummary></StudentMeetingSummary>
</template>

<script>
import StudentMeetingSummary from "@/components/shared/student/MeetingSummary.vue";

export default {
	name: "MentorStudentMeetingSummary",

	components: {
		StudentMeetingSummary,
	},
};
</script>

<style></style>
