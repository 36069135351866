<template>
	<div class="pa-5">
		<div v-if="meeting">
			<v-form ref="parentForm">
				<v-row v-if="can_modify">
					<!-- Meeting Number -->
					<!-- <v-col sm="3">
						<div :class="textClasses">{{ $t("app.meeting_no") }}</div>
						<TextBox
							v-model="meeting.id"
							:prop_value="meeting.id"
							:outlined="true"
							:disabled="submitted"
							:hide_details="false"
							:readonly="true"
						></TextBox>
					</v-col> -->

					<!-- Features Icons -->
					<v-col>
						<div
							v-for="(icon, index) in icons"
							:key="index"
							class="d-inline-block mx-2"
						>
							<v-icon
								v-if="icon.condition"
								color="greyDarken4"
								size="25"
								@click="handleFunctionCall(icon.method_name)"
							>
								{{ $vuetify.icons.values[icon.name] }}
							</v-icon>
						</div>
					</v-col>
				</v-row>

				<v-row no-gutters class="mt-4" v-if="can_modify">
					<!-- Meeting Date -->
					<v-col class="pe-3"
						><div :class="textClasses">{{ $t("app.meeting_date") }}</div>
						<DatePicker
							v-model="meeting.meeting_date"
							:prop_date="meeting.meeting_date"
							:outlined="true"
							:solo="true"
							:disabled="submitted"
							@update-value="updateMeeting"
						></DatePicker>
					</v-col>

					<!-- Meeting title -->
					<v-col sm="4" class="pe-3">
						<div :class="textClasses">
							{{ $t("app.meeting_title") }}
						</div>
						<TextBox
							v-model="meeting.title"
							type="v-textarea"
							:rows="1"
							:prop_value="meeting.title"
							:outlined="true"
							:disabled="submitted"
							:hide_details="false"
							:rules="[rules.required]"
							@update-value="updateMeeting"
						></TextBox>
					</v-col>

					<!-- Participants of the meeting -->
					<v-col sm="4"
						><div :class="textClasses">
							{{ $t("app.meeting_participants") }}
						</div>
						<TextBox
							v-model="meeting.participants"
							type="v-textarea"
							:rows="1"
							:prop_value="meeting.participants"
							:outlined="true"
							:disabled="submitted"
							:hide_details="false"
							placeholder="app.placeholder.write_your_and_mentor_names"
							@update-value="updateMeeting"
							:rules="[rules.required]"
						></TextBox>
					</v-col>
				</v-row>

				<!-- Agenda of meeting (It could be multiple) -->
				<Agenda
					:submitted="submitted"
					:prop_meeting_id="prop_meeting && prop_meeting.id"
					:prop_border="agenda_err_border"
				></Agenda>

				<!-- To do list -->
				<TodoItems
					:submitted="submitted"
					:prop_meeting_id="prop_meeting && prop_meeting.id"
					:prop_border="todo_err_border"
				></TodoItems>
			</v-form>

			<!-- Confirmation box for delete operation -->
			<TheConfirmation
				v-model="dialog"
				:dialog="dialog"
				@cancel="dialog = false"
				@confirm="deleteMeeting()"
			></TheConfirmation>
		</div>
	</div>
</template>

<script>
import DatePicker from "@/components/plugins/DatePicker";
import TextBox from "@/components/plugins/TextBox";
import Agenda from "@/components/shared/tools/meetingSummary/Agenda.vue";
import TodoItems from "@/components/shared/tools/meetingSummary/TodoItems.vue";
import moment from "moment";
import TheConfirmation from "@/components/layout/TheConfirmation";
import rules from "@/utils/validation";

export default {
	name: "MeetingDetail",

	props: {
		prop_meeting: {
			required: false,
		},
		can_modify: {
			default: true,
		},
	},

	data() {
		return {
			moment,
			rules,
			textClasses: "body-1 greyDarken4--text font-weight-bold mb-1",
			meeting: null,
			agendas: null,
			dialog: false,
			submitted: false,
			agenda_err_border: false,
			todo_err_border: false,
		};
	},

	components: {
		DatePicker,
		TextBox,
		Agenda,
		TodoItems,
		TheConfirmation,
	},

	computed: {
		icons() {
			return [
				{
					name: "edit",
					condition: this.submitted,
					method_name: "makeEditable",
				},
				{
					name: "delete",
					condition: true,
					method_name: "openConfirmationDialog",
				},
				// {
				// 	name: "email",
				// 	condition: true,
				// 	method_name: null,
				// },
				// {
				// 	name: "printer",
				// 	condition: true,
				// 	method_name: null,
				// },
			];
		},
	},

	created() {
		this.$eventBus.$on("ms-submit", async (payload) => {
			await this.validateAndSubmitSummary();
		});
	},

	mounted() {
		this.getMeetingSummary();
	},

	methods: {
		handleFunctionCall(method_name) {
			this[method_name]();
		},

		openConfirmationDialog() {
			this.dialog = true;
		},

		makeEditable() {
			this.submitted = false;

			// Update to footer
			this.$eventBus.$emit("ms-status-updated", {
				status: this.submitted,
			});
		},

		async getMeetingSummary() {
			try {
				this.$loader.start();

				if (this.prop_meeting) {
					this.meeting = this.prop_meeting;
				} else {
					let res = await this.$store.dispatch("meetingSummary/get", {
						meeting_id: this.$route.params.id,
					});
					this.meeting = res;
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateMeeting($event) {
			try {
				this.meeting.meeting_date = moment(this.meeting.meeting_date).format(
					"YYYY-MM-DD h:mm",
				);
				await this.$store.dispatch("meetingSummary/update", {
					meeting: this.meeting,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteMeeting() {
			try {
				this.$loader.start();

				// Delete Meeting
				await this.$store.dispatch("meetingSummary/delete", {
					meeting_id: this.meeting.id,
				});

				// Redirect to meetings list page
				this.$router.push({
					name: "MeetingsList",
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async validateAndSubmitSummary() {
			try {
				this.$loader.start();

				// Validate form fields
				await this.validateFormFields();

				// Validate required number of agendas
				await this.validateAgenda();

				// Validate required number of todo items
				await this.validateTodoItems();

				this.submitted = true;

				// Update to footer
				this.$eventBus.$emit("ms-status-updated", {
					status: this.submitted,
				});

				// Notify success
				this.$announce.success("app.ntfy.succ.summary_submitted");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		validateFormFields() {
			var valid = true;

			// This ref is defined in this component
			let parent = this.$refs.parentForm;

			// Get all
			if (parent && parent.$children && parent.$children.length) {
				parent.$children.forEach((child) => {
					// If child form matched with ref then check this is validate or not.
					if (child.$refs.hasOwnProperty("form")) {
						valid &= child.$refs["form"].validate();
					}
				});
			}

			if (!valid) {
				throw new Error("app.ntfy.err.fill_required_fields", {
					cause: "werCustom",
				});
			}
		},

		async validateAgenda() {
			let agendas = await this.$store.dispatch("meetingSummary/listAgendas", {
				meeting_id: this.$route.params.id,
			});

			if (agendas.length < 1) {
				this.agenda_err_border = true;
				throw new Error("app.ntfy.err.min_agenda_required", {
					cause: "werCustom",
				});
			}

			this.agenda_err_border = false;
		},

		async validateTodoItems() {
			let todo_items = await this.$store.dispatch(
				"meetingSummary/listTodoItems",
				{
					meeting_id: this.$route.params.id,
				},
			);
			if (todo_items.length < 1) {
				this.todo_err_border = true;

				throw new Error("app.ntfy.err.min_todo_items_required", {
					cause: "werCustom",
				});
			}

			this.todo_err_border = false;
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("ms-submit");
	},
};
</script>

<style scoped>
>>> .title-col-wr
	.v-text-field
	> .v-input__control
	> .v-input__slot
	> .v-text-field__slot {
	text-decoration: underline !important;
}
</style>
